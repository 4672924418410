// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
// import { getAddresTypesSimpleList, getAddressUserList, adderssAdd } from '@/services/members.js'
import { getAddresTypesSimpleList, adderssAdd } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      user_id: [],
      btnTxt: this.$t('button.confirm'),
      addressTypeOptions: [
        {
          label: this.$t('form.members.private'),
          value: 'private'
        },
        {
          label: this.$t('form.members.business'),
          value: 'business'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    if (this.$route.name === 'memberAddressAdd') {
      this.user_id = this.$route.params.id
    }
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getAddresTypesSimpleList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[9].options = this.addressTypeOptions
          this.formData[0].createDateItem[8].options = res.data.address_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
      // getAddressUserList('').then(res => {
      //   this.remoteMethod(res, { prop: 'user_id' })
      // })
    },
    handleChange (data, prop) {
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.user_id = this.user_id
      const params = {
        address: obj
      }
      adderssAdd(params).then(res => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.back(-1)
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    remoteMethod (data, item) {
      if (item.prop === 'user_id') {
        if (data) {
          this.formData[0].createDateItem[8].options = data.data.users.map(it => {
            return {
              value: it.id,
              name: it.name
            }
          })
        } else {
          this.formData[0].createDateItem[8].options = []
        }
        this.formData[0].createDateItem[8].Object.loading = false
      }
    }
  }
}
